import { Fragment } from 'react';
import './ProgressBar.scss';

const ProgressBar = (props) => {
	const pageNumbers = [];
	for (let i = 0; i < props.pages; i++) {
		let classes = ['number'];
		if (i === props.activePage) classes.push('active');
		pageNumbers.push(
			<Fragment key={'frag' + i}>
				<div
					className={classes.join(' ')}
					onClick={() => props.pageChange(i)}
				>
					{i + 1}
				</div>
				<div className='line'></div>
			</Fragment>
		);
	}
	return props.pages > 1 ? (
		<div className='ProgressBar'>
			<h2>Fortschritt</h2>
			{pageNumbers}
		</div>
	) : null;
};
export default ProgressBar;
