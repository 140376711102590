import './Hero.scss'

const Hero = (props) => {
	return (
		<div className='Hero'>
			<div className="container">
				<img src={props.image} alt='' />
				<h1>{props.children}</h1>
			</div>
		</div>
	)
}
export default Hero
