import Question from './Question/Question';
import './Questions.scss';
import ValidationContext from '../../context/validationContext';
import { useContext } from 'react';
import ProgressBar from './ProgressBar/ProgressBar';
import Pagination from './Pagination/Pagination';
const Questions = (props) => {
	const validationContext = useContext(ValidationContext);
	return (
		<form
			className='Questions container'
			onSubmit={(e) => validationContext.submit(e)}
		>
			<ProgressBar
				pages={props.pages}
				activePage={props.activePage}
				pageChange={props.pageChange}
			/>
			<h2>{props.title}</h2>
			{props.questions.map((question, index) => {
				return (
					<Question
						key={question.id}
						index={question.id.replace('q_', '')}
						type={question.type}
						text={question.content}
						sub={question.subquestion}
						possibleAnswers={question.possibleAnswers}
						givenAnswers={question.givenAnswer}
						changed={(event) => props.changed(event, question.id)}
					></Question>
				);
			})}
			<Pagination
				pageChange={props.pageChange}
				activePage={props.activePage}
				pages={props.pages}
			/>
		</form>
	);
};
export default Questions;
