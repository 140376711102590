import Contact from '../Contact/Contact';
import './Intro.scss';
import whiteArrow from '../../assets/icons/arrow-right-white.svg';

const Intro = (props) => {
	return (
		<div className='Intro'>
			<div className='container'>
				<h2 className='Intro-title col-12 col-lg-10'>
					Herzlich willkommen zu unserer Umfrage <br />{' '}
					„Mitarbeiterwohnen für Berliner Landesunternehmen und die
					öffentliche Verwaltung“
				</h2>
				<div className='flex-wrap p-top-3'>
					<div className='Intro-text thirds-3 thirds-lg-2 p-right-1'>
						<p>
							In unserem Segment „Mitarbeiterwohnen“ bieten wir
							möblierte und unmöblierte Apartments an. Diese
							stellen wir vorrangig Landesunternehmen und ihren
							Kooperationspartnern zur Verfügung. Damit wir unser
							Angebot bestmöglich auf Ihre Bedürfnisse zuschneiden
							können, würden wir uns sehr freuen, wenn Sie uns
							kurz einige Fragen beantworten. Ihre Rückmeldungen
							werden sehr wertvoll für uns sein.
						</p>
						<p>Unsere Umfrage besteht aus 6 Fragen.</p>
						<p>
							Bei Fragen oder Anmerkungen wenden Sie sich bitte an
							Frau Smolarek,{' '}
							<a href='mailto:mitarbeiterwohnen@berlinovo.de'>
								mitarbeiterwohnen@berlinovo.de
							</a>
						</p>
					</div>
					<div className='thirds-lg-1'>
						{' '}
						<Contact />
					</div>
					<div className='btn-container'>
						<button className='start-btn' onClick={props.click}>
							zur Umfrage
							<img src={whiteArrow} alt='white arrow icon' />
						</button>
					</div>
				</div>
			</div>

			<div className='purple-line'></div>
		</div>
	);
};

export default Intro;
