import './Header.scss';


const Header = (props) => {
	return (
		<div className='Header'>
			<div className='container'>
				<div className='flex justify-content-between'>
					{/* <ul>
						<li>
							<a href='#sad'>Lorem</a>
						</li>
						<li>
							<a href='#sad'>Ipsum</a>
						</li>
						<li>
							<a href='#sad'>Init</a>
						</li>
						<li>
							<a href='#asd'>Dolor</a>
						</li>
					</ul> */}
					<a className='branding' href='/'>
						<img src={props.logo} alt='Site Logo' />
					</a>
				</div>
			</div>
		</div>
	);
};
export default Header;
