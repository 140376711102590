import './Answer.scss';
const Answer = (props) => {
	let answers;
	switch (props.type) {
		case 'checkbox':
			answers = props.possibleAnswers.map((answer, index) => {
				return (
					<label key={answer.value + index} className='b-checkbox'>
						{answer.text}
						<input
							id={answer.value + index}
							type='checkbox'
							onChange={props.changed}
							value={answer.value}
							checked={
								props.givenAnswers.includes(answer.value)
									? true
									: false
							}
						/>
						<span className='checkmark'></span>
					</label>
				);
			});
			break;

		case 'radio':
			answers = props.possibleAnswers.map((answer, index) => {
				return (
					<label key={answer.value + index} className='b-radio'>
						{answer.text}
						<input
							id={answer.value + index}
							name={'Question_' + props.questionNumber}
							type='radio'
							onChange={props.changed}
							value={answer.value}
							checked={
								props.givenAnswers.includes(answer.value)
									? true
									: false
							}
						/>
						<span className='radio'></span>
					</label>
				);
			});
			break;

		case 'select':
			answers = (
				<select
					onChange={props.changed}
					defaultValue={
						props.givenAnswers[0]
							? props.givenAnswers[0]
							: 'Bitte Antwort wählen'
					}
				>
					<option key='default-option' disabled>
						Bitte Antwort wählen
					</option>
					{props.possibleAnswers.map((answer, index) => {
						return <option key={answer.value + index}>{answer.text}</option>;
					})}
				</select>
			);
			break;

		case 'text':
			answers = (
				<textarea
					cols='30'
					rows='10'
					onChange={props.changed}
					value={props.givenAnswers[0]}
				></textarea>
			);
			break;
		case 'number':
			answers = (
				<input
					type='number'
					name={'Question_' + props.questionNumber}
					onChange={props.changed}
					value={props.givenAnswers[1] ? props.givenAnswers[1] : ''}
					min="0"
				/>
			);
			break;
		default:
			answers = (
				<input
					type='text'
					name={'Question_' + props.questionNumber}
					onChange={props.changed}
					value={props.givenAnswers[0] ? props.givenAnswers : ''}
				/>
			);
			break;
	}

	return <div className='Answer'>{answers}</div>;
};
export default Answer;
