import './Contact.scss';
const Contact = (props) => {
	return (
		<div className='Contact'>
			<h2>Kontakt</h2>
			<p>
				Berlinovo<br /> Immobilien Gesellschaft mbH
				<br />
				<strong>Frau Doreen Smolarek</strong>
			</p>
			<p>
				Hallesches Ufer 74–76 <br />
				10963 Berlin <br />
				Tel.: +49 30 25441 - 3132 <br />
				Fax: +49 30 25441 - 662
				<br />
				E-Mail:{' '}
				<a href='mailto:mitarbeiterwohnen@berlinovo.de'>
					mitarbeiterwohnen@berlinovo.de
				</a>
			</p>
		</div>
	);
};
export default Contact;
