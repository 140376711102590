import './Pagination.scss';
import leftArrow from '../../../assets/icons/arrow-left.svg';
import rightArrow from '../../../assets/icons/arrow-right.svg';
import whiteArrow from '../../../assets/icons/arrow-right-white.svg';
const Pagination = (props) => {

	return (
		<div className='Pagination'>
			{props.activePage > 0 ? (
				<div
					onClick={() => props.pageChange(props.activePage - 1)}
					className='page-prev'
				>
					<img src={leftArrow} alt='arrow icon' /> zur vorherigen
					Frage
				</div>
			) : null}

			{props.activePage < props.pages - 1 ? (
				<div
					onClick={() => props.pageChange(props.activePage + 1)}
					className='page-next'
				>
					zur nächsten Frage <img src={rightArrow} alt='arrow icon' />
				</div>
			) : null}
			{props.activePage < props.pages - 1 ? null : (
				<div className='btn-container'>
					<button
						className='submit-btn'
						type='submit'						
					>
						abschicken{' '}
						<img src={whiteArrow} alt='white arrow icon' />
					</button>
				</div>
			)}
		</div>
	);
};
export default Pagination;
