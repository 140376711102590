import './Question.scss';
import Answer from './Answer/Answer';
import { useContext } from 'react';
import ValidationContext from '../../../context/validationContext';
const Question = (props) => {
	const validationContext = useContext(ValidationContext);
	const classes = props.sub ? 'Question Subquestion' : 'Question';
	return (
		<div className={classes}>
			{props.text}
			<Answer
				type={props.type}
				possibleAnswers={props.possibleAnswers}
				givenAnswers={props.givenAnswers}
				questionNumber={props.index}
				changed={props.changed}
			/>
			{(props.givenAnswers.length === 0 ||
				props.givenAnswers[0].length === 0) &&
			validationContext.displayError ? (
				<p className='error-msg' style={{ color: 'Red' }}>
					Bitte beantworten diese Frage um fortzufahren.
				</p>
			) : (
				''
			)}
		</div>
	);
};
export default Question;
